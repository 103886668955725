.hero-container {
  padding: 95px 0;
  background-color: var(--dino-white);
  .hero-row {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    flex: 1;
    gap: 20px;
  }
  .hero-column {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .hero-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .hero-logo-container {
    display: flex;
    align-items: center;
    gap: 20px;
    .hero-logo-description {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 15px;
      border-radius: 10px;
      border: 1px solid var(--dino-grey-10);
    }
  }
  .hero-slogan-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .hero-slogan-title {
      display: flex;
      flex-direction: column;
      font-size: 96px;
      font-weight: 700;
      line-height: 82px;
      letter-spacing: -3px;
      color: var(--dino-dark);
    }
    .hero-slogan-description {
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      color: var(--dino-dark);
    }
  }
  .hero-description-container {}
  .hero-cta-container {
    display: flex;
    align-items: center;
    gap: 30px;
    .secondary {
      justify-content: flex-start;
    }
  }
  .hero-images-container {
    .hero-images-row-1 {
      display: grid;
      grid-template-columns: 390px 170px;
      grid-template-rows: 170px;
    }
    .hero-images-row-2 {
      display: grid;
      grid-template-columns: 220px 340px;
      grid-template-rows: 170px;
    }
    .hero-images-row-3 {
      display: grid;
      grid-template-columns: 220px 170px 170px;
      grid-template-rows: 280px;
    }
    .hero-image {
      max-width: 100%;
    }
    .hero-image-wrapper {
      border-radius: 999px;
      overflow: hidden;
      &.hero-image-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid var(--dino-dark);
        font-size: 64px;
        font-weight: 700;
        line-height: 77px;
        letter-spacing: -0.04em;
        color: var(--dino-dark);
        .hero-image-cursor {
          color: var(--dino-green);
        }
      }
      &.hero-image-5 {
        position: relative;
        overflow: visible;
        .rrr-image {
          position: absolute;
          width: 140px;
          height: 100px;
          top: -42px;
          right: -65px;
          transform: rotate(24deg);
        }
      }
      &.hero-image-6 {
        border: 3px solid var(--dino-dark);
      }
    }
  }
}

@media (min-width: 1200px) {
  .hero-container {
    height: calc(100vh - 100px);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199px) {
  .hero-container {
    .hero-row {
      max-width: 960px;
    }
    .hero-content {
      gap: 30px;
    }
    .hero-slogan-container {
      gap: 20px;
      .hero-slogan-title {
        font-size: 76px;
        line-height: 70px;
      }
      .hero-slogan-description {
        font-size: 20px;
        line-height: 20px;
      }
    }
    .hero-images-container {
      .hero-images-row-1 {
        grid-template-columns: 328px 142px;
        grid-template-rows: 142px;
      }
      .hero-images-row-2 {
        grid-template-columns: 186px 284px;
        grid-template-rows: 142px;
      }
      .hero-images-row-3 {
        grid-template-columns: 186px 142px 142px;
        grid-template-rows: 234px;
      }
    }
  }
}

@media (max-width: 576px) {
  .hero-container {
    padding: 10px 16px 50px;
    background-color: #F1F1F1;
    .hero-row {
      max-width: 100%;
      flex-direction: column;
      gap: 50px;
    }
    .hero-column {
      justify-content: center;
    }
    .hero-content {
      flex: 1;
      gap: 40px;
      padding: 0 16px;
      max-width: 311px;
    }
    .hero-logo-container {
      justify-content: center;
      .hero-logo-image {
        width: 129px;
        height: 38px;
      }
      .hero-logo-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        padding: 0 0 0 30px;
        border-radius: 0;
        border: 0;
        border-left: 2px solid var(--dino-grey-10);
        .hero-logo-description-item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
    .hero-slogan-container {
      gap: 20px;
      .hero-slogan-title {
        font-size: 56px;
        line-height: 50px;
        align-items: center;
      }
      .hero-slogan-description {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.02em;
        text-align: center;
      }
    }
    .hero-cta-container {
      flex-direction: column;
      gap: 30px;
      .button {
        width: 100%;
      }
      .secondary {
        justify-content: center;
      }
    }
    .hero-images-container {
      margin: 0 auto;
      .hero-images-row-1 {
        grid-template-columns: 239px 104px;
        grid-template-rows: 104px;
      }
      .hero-images-row-2 {
        grid-template-columns: 135px 208px;
        grid-template-rows: 104px;
      }
      .hero-images-row-3 {
        grid-template-columns: 135px 104px 104px;
        grid-template-rows: 172px;
      }
      .hero-image-wrapper {
        &.hero-image-1 {
          font-size: 32px;
          line-height: 38px;
          background-color: var(--dino-white);
        }
        &.hero-image-5 {
          .rrr-image {
            width: 85px;
            top: -25px;
            right: -40px;
            transform: rotate(24deg);
          }
        }
      }
    }
  }
}
