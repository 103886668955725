.running-string {
  height: 100px;
  background: var(--dino-green);
  display: flex;
  align-items: center;
  .running-string-item {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    padding: 0 4px;
    color: var(--dino-white);
    text-transform: uppercase;
  }
}

@media (max-width: 1199px) {
  .running-string {
    height: 64px;
  }
}
