.footer {
  padding: 100px 0;
  background: var(--dino-dark);
  .footer-row {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-divider {
    height: 200px;
    width: 1px;
    background: rgba(250, 250, 250, .3);
  }
  .footer-column {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 39px;
    color: var(--dino-white);
    &.footer-column-1 {
      align-items: flex-start;
      gap: 25px;
    }
    &.footer-column-2 {
      gap: 40px;
      width: 400px;
    }
    &.footer-column-3 {
      width: 160px;
    }
  }
  .footer-logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
  .footer-description-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    max-width: 344px;
  }
  .footer-copyright-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
  .footer-made-with-love {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--dino-grey-10);
    font-size: 14px;
  }
  .footer-copyright {}
  .footer-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
  }
  .footer-form-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
  }
  .footer-form {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: var(--dino-white);
    border-radius: 99px;
    border: 1px solid var(--border-color);
    .footer-input {
      display: flex;
      flex: 1;
      padding: 0 10px 0 20px;
      border: none;
      background: transparent;
      outline: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: var(--dino-dark);
      &::placeholder {
        color: var(--dino-grey-30);
      }
    }
  }
  .footer-links-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    opacity: .7;
    color: var(--dino-white);
  }
  .footer-terms {
    text-decoration: underline;
    cursor: pointer;
  }
  .footer-interested {
    text-decoration: underline;
    cursor: pointer;
  }
  .footer-social-wrapper {
    display: flex;
    gap: 5px;
  }
}

@media (max-width: 1199px) {
  .footer {
    .footer-row {
      max-width: 960px;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 50px 16px;
    .footer-row {
      flex-direction: column;
    }
    .footer-divider {
      display: none;
    }
    .footer-column {
      height: auto;
      gap: 30px;
      &.footer-column-1 {
        padding-right: 0;
        padding-bottom: 50px;
      }
      &.footer-column-2 {
        gap: 30px;
        width: 100%;
        padding: 50px 0;
        border: none;
        border-top: 1px solid rgba(250, 250, 250, .3);
        border-bottom: 1px solid rgba(250, 250, 250, .3);
      }
      &.social-column {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid rgba(250, 250, 250, .3);
      }
      &.footer-column-3 {
        width: 100%;
        padding-left: 0;
        padding-top: 50px;
        flex-direction: column-reverse;
        gap: 10px;
        .footer-gdpr-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    .footer-logo-wrapper {
      align-items: center;
    }
    .footer-description-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }
    .footer-made-with-love-wrapper {
      width: 100%;
    }
    .footer-made-with-love {
      flex: 1;
    }
    .footer-social-wrapper {
      gap: 50px;
    }
    .footer-copyright-wrapper {
      padding-top: 50px;
      flex-direction: column;
      gap: 20px;
    }
    .footer-copyright {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      opacity: .5;
    }
    .footer-terms {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      opacity: .7;
    }
    .footer-interested {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
    }
    .footer-form-wrapper {
      gap: 10px;
      .button {
        width: 100%;
      }
    }
    .footer-form-title {
      font-size: 20px;
      line-height: 20px;
      padding-bottom: 20px;
    }
    .footer-form {
      height: 50px;
      .footer-input {
        padding: 0 15px;
      }
    }
  }
}
