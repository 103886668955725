.section-three {
  padding: 0 0 100px;
  .section-three-row {
    margin: 0 auto;
    max-width: 1140px;
  }
  .section-three-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 753px;
    margin-bottom: 45px;
  }
  .section-three-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .section-three-cards-row-1 {
    display: grid;
    grid-template-columns: auto 463px;
    grid-template-rows: 400px;
    gap: 20px;
  }
  .section-three-cards-row-2 {
    display: grid;
    grid-template-columns: 463px auto;
    grid-template-rows: 400px;
    gap: 20px;
  }
  .section-three-card {
    border-radius: 40px;
    padding: 37px 37px 0 37px;
    .section-three-card-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 430px;
      color: var(--dino-white);
      .card-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
      }
      .card-description {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
      }
    }
    &.card-1 {
      padding: 0;
      background: var(--dino-purple);
      overflow: hidden;
      .section-three-card-header {
        padding: 40px 40px 35px;
      }
      .section-three-card-1-items-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .section-three-card-1-items-row {
          display: flex;
          gap: 6px;
          margin: 0 3px;
          .section-three-card-1-item {
            padding: 25px;
            border-radius: 999px;
            background: var(--dino-white);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 26px;
            margin-bottom: 8px;
            box-shadow: 0 4px 9px 0 rgba(54, 62, 120, 0.3);
            .section-three-card-1-item-left {
              display: flex;
              align-items: center;
              gap: 6px;
              font-size: 18px;
              font-weight: 700;
              line-height: 20px;
              color: var(--dino-dark);
            }
            .section-three-card-1-item-right {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 7px 11px;
              border-radius: 7px;
              background: var(--dino-bg-default);
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              color: var(--dino-grey-50);
            }
          }
        }
      }
    }
    &.card-2 {
      position: relative;
      padding: 0;
      background: var(--dino-white);
      border: 3px solid var(--dino-dark);
      overflow: hidden;
      .section-three-card-header {
        padding: 37px 37px 0 37px;
      }
      .card-title {
        color: var(--dino-dark);
      }
      .card-description {
        color: var(--dino-grey-70);
      }
      .pounder {
        position: absolute;
        top: 0;
        left: -1px;
        width: 100%;
      }
    }
    &.card-3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 35px;
      background: var(--dino-white);
      border: 3px solid var(--dino-dark);
      .card-title {
        color: var(--dino-dark);
      }
      .card-description {
        color: var(--dino-grey-70);
      }
      .section-three-card-3-image-wrapper {
        width: 100%;
        overflow: hidden;
        .section-three-card-3-image {
          max-width: 100%;
        }
      }
    }
    &.card-4 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;
      background: var(--dino-green);
      .section-three-card-4-image-wrapper {
        width: 100%;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        .section-three-card-4-image {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .section-three {
    .section-three-row {
      max-width: 960px;
    }
  }
}

@media (max-width: 576px) {
  .section-three {
    padding: 100px 0 0;
    .section-three-row {
      max-width: 100%;
      padding: 0 16px;
    }
    .section-three-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 40px;
      max-width: 100%;
      padding: 0 16px;
      margin-bottom: 50px;
    }
    .section-three-cards-row-1 {
      grid-template-columns: 1fr;
      grid-template-rows: 330px 330px;
    }
    .section-three-cards-row-2 {
      grid-template-columns: 1fr;
      grid-template-rows: 330px 330px;
    }
    .section-three-card {
      border-radius: 30px;
      padding: 30px 30px 0 30px;
      .section-three-card-header {
        text-align: center;
        .card-title {

        }
        .card-description {
          letter-spacing: -0.02em;
        }
      }
      &.card-1 {
        .section-three-card-header {
          padding: 30px;
        }
        .section-three-card-1-items-wrapper {
          .section-three-card-1-items-row {
            gap: 6px;
            margin: 0 3px;
            .section-three-card-1-item {
              padding: 18px;
              gap: 18px;
              margin-bottom: 6px;
              box-shadow: 0 2px 4px 0 #76130641;
              .section-three-card-1-item-left {
                gap: 5px;
                font-size: 13px;
                line-height: 14px;
                img {
                  width: 22px;
                  height: 22px;
                }
              }
              .section-three-card-1-item-right {
                gap: 3px;
                padding: 5px 8px;
                border-radius: 5px;
                font-size: 9px;
                line-height: 13px;
                img {
                  width: 9px;
                  height: 9px;
                }
              }
            }
          }
        }
      }
      &.card-2 {
        .section-three-card-header {
          padding: 30px 30px 0 30px;
        }
        .card-title {

        }
        .card-description {

        }
        .pounder {

        }
      }
      &.card-3 {
        gap: 15px;
        .card-title {

        }
        .card-description {

        }
        .section-three-card-3-image-wrapper {
          .section-three-card-3-image {

          }
        }
      }
      &.card-4 {
        .section-three-card-4-image-wrapper {
          .section-three-card-4-image {

          }
        }
      }
    }
  }
}
