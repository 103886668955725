.section-two-container {
  transition: background-color .5s;
  &.mobile {
    display: none;
  }
  &.desktop {
    position: relative;
    height: 300vh;
    .section-two-row {
      margin: 0 auto;
      max-width: 1140px;
      display: flex;
      align-items: center;
      flex: 1;
      gap: 20px;
      position: sticky;
      top: 0;
      height: 100vh;
    }
    .section-two-column {
      display: flex;
      align-items: center;
      flex: 1;
      height: 700px;
      &.column-right {
        position: relative;
      }
      &.column-left {

      }
    }
    .section-two-content {
      display: flex;
      flex-direction: column;
      gap: 45px;
      max-width: 460px;
    }
    .section-two-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 395px;
    }
    .slider-pagination-wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    .slider-pagination-item {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--dino-grey-30);
      cursor: pointer;
      &.active {
        .slider-pagination-description{
          color: var(--dino-grey-70);
        }
      }
      .slider-pagination-progress {
        width: 4px;
        height: 100%;
        overflow: hidden;
        border-radius: 2px;
        position: absolute;
        top: 0;
        left: -24px;
        background: var(--dino-grey-10);
      }
      .slider-pagination-progress-filled {
        width: 100%;
      }
      .slider-pagination-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        color: var(--dino-grey-30);
        transition: color .3s;
      }
      .slider-pagination-description{
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        color: var(--dino-grey-30);
      }
    }
    &.slide-1 {
      .slider-pagination-progress-filled {
        background: var(--dino-green);
      }
      .slider-pagination-item {
        &.active {
          .slider-pagination-title {
            color: var(--dino-green);
          }
        }
      }
    }
    &.slide-2 {
      .slider-pagination-progress-filled {
        width: 100%;
        background: var(--dino-blue);
      }
      .slider-pagination-item {
        &.active {
          .slider-pagination-title {
            color: var(--dino-blue);
          }
        }
      }
    }
    &.slide-3 {
      .slider-pagination-progress-filled {
        background: var(--dino-orange);
      }
      .slider-pagination-item {
        &.active {
          .slider-pagination-title {
            color: var(--dino-orange);
          }
        }
      }
    }
    .section-two-images-container {
      box-sizing: border-box;
      width: 860px;
      height: 700px;
      border-radius: 80px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 20%;
        z-index: -1;
        background: url('../../assets/images/section-two-bg.png') var(--dino-green);
        background-size: 100px 100px;
      }
      &.slide-1 {
        background: var(--dino-green);
      }
      &.slide-2 {
        background: var(--dino-blue);
      }
      &.slide-3 {
        background: var(--dino-orange);
      }
      .section-two-images-row {
        width: 100%;;
      }
      .section-two-image {
        width: 100%;
      }
      .section-two-image-wrapper {
        height: 700px;
      }
    }
  }
  &.mobile {}
}

@media (max-width: 1199px) {
  .section-two-container {
    &.desktop {
      .section-two-row {
        max-width: 960px;
      }
    }
  }
}

@media (max-width: 576px) {
  .section-two-container {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
      padding: 100px 16px 0;
      .section-two-row {
        margin: 0 auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
      .section-two-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        max-width: 395px;
      }
      .section-two-carousel {
        width: 100%;
        height: 100%;
      }
    }
  }
}
