.section-four-container {
  padding: 100px 0;
  .section-four-row {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    gap: 20px;
  }
  .section-four-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  .section-four-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 460px;
  }
  .section-four-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: var(--dino-grey-70);
  }
  .section-four-images-container {
    .section-four-images-row-1 {
      display: grid;
      grid-template-columns: 170px 390px;
      grid-template-rows: 170px;
    }
    .section-four-images-row-2 {
      display: grid;
      grid-template-columns: 170px 170px 220px;
      grid-template-rows: 280px;
    }
    .section-four-images-row-3 {
      display: grid;
      grid-template-columns: 340px 220px;
      grid-template-rows: 170px;
    }
    .section-four-image {
      max-width: 100%;
    }
    .section-four-image-wrapper {
      border-radius: 999px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      &.section-four-image-1 {
        position: relative;
        background: var(--dino-orange);
        z-index: 1;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 20%;
          z-index: -1;
          background: url('../../assets/images/section-two-bg.png') var(--dino-orange);
          background-size: 100px 100px;
        }
      }
      &.section-four-image-2 {
        background: var(--dino-white);
        border: 3px solid var(--dino-dark);
      }
      &.section-four-image-3 {
        position: relative;
        background: var(--dino-purple);
        z-index: 1;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 20%;
          z-index: -1;
          background: url('../../assets/images/section-two-bg.png') var(--dino-purple);
          background-size: 100px 100px;
        }
      }
      &.section-four-image-4 {
        background: var(--dino-white);
        border: 3px solid var(--dino-dark);
      }
      &.section-four-image-5 {
        position: relative;
        background: var(--dino-blue);
        z-index: 1;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 20%;
          z-index: -1;
          background: url('../../assets/images/section-two-bg.png') var(--dino-blue);
          background-size: 100px 100px;
        }
      }
      &.section-four-image-6 {
        position: relative;
        background: var(--dino-green);
        z-index: 1;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 20%;
          z-index: -1;
          background: url('../../assets/images/section-two-bg.png') var(--dino-green);
          background-size: 100px 100px;
        }
      }
      &.section-four-image-7 {
        position: relative;
        background: var(--dino-yellow);
        z-index: 1;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 20%;
          z-index: -1;
          background: url('../../assets/images/section-two-bg.png') var(--dino-yellow);
          background-size: 100px 100px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .section-four-container {
    .section-four-row {
      max-width: 960px;
    }
    .section-four-images-container {
      .section-four-images-row-1 {
        grid-template-columns: 142px 328px;
        grid-template-rows: 142px;
      }
      .section-four-images-row-2 {
        grid-template-columns: 142px 142px 186px;
        grid-template-rows: 234px;
      }
      .section-four-images-row-3 {
        grid-template-columns: 284px 186px;
        grid-template-rows: 142px;
      }
      .section-four-image-wrapper {
        &.section-four-image-1 {}
        &.section-four-image-2 {}
        &.section-four-image-3 {}
        &.section-four-image-4 {}
        &.section-four-image-5 {}
        &.section-four-image-6 {}
        &.section-four-image-7 {}
      }
    }
  }
}

@media (max-width: 576px) {
  .section-four-container {
    padding: 100px 16px;
    .section-four-row {
      margin: 0 auto;
      max-width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 50px;
    }
    .section-four-column {
      justify-content: center;
    }
    .section-four-content {
      max-width: 311px;
      align-items: center;
      text-align: center;
      gap: 40px;
    }
    .section-four-description {
      color: var(--dino-dark);
    }
    .section-four-images-container {
      margin: 0 auto;
      .section-four-images-row-1 {
        grid-template-columns: 104px 239px;
        grid-template-rows: 104px;
      }
      .section-four-images-row-2 {
        grid-template-columns: 104px 104px 135px;
        grid-template-rows: 170px;
      }
      .section-four-images-row-3 {
        grid-template-columns: 208px 135px;
        grid-template-rows: 104px;
      }
      .section-four-image-wrapper {
        &.section-four-image-1 {}
        &.section-four-image-2 {}
        &.section-four-image-3 {}
        &.section-four-image-4 {}
        &.section-four-image-5 {}
        &.section-four-image-6 {}
        &.section-four-image-7 {}
      }
    }
  }
}
