.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  video {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .slider-pagination-item {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .slider-pagination-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    color: var(--dino-dark);
  }
  .slider-pagination-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--dino-grey-70);
  }
}
.slider-dots-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
}
.slider-dot {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 1px solid var(--dino-green);
  &.active {
    background: var(--dino-green);
  }
}
