:root {
  --main-opacity: 0.9;
  // interface colors
  --dino-white: #FAFAFA;
  --dino-dark: #2A2B3B; // default text color
  --dino-grey: #EEEEEE;
  --dino-blue: #5B93A9;
  --dino-purple: #666DA5;
  --dino-green: #7E9D00;
  --dino-red: #FF5656;
  --dino-error: #FF5656;
  --dino-green-bg-default: #7E9D00;
  --dino-green-bg-hover: #98B133D5;
  --dino-green-bg-pressed: #A5BB4D;
  --dino-color-default: #C0C0C5;
  --dino-yellow: #EDB833;
  --dino-orange: #D36700;
  --dino-text-color: #95959D;
  --dino-grey-70: #6A6B76;
  --dino-grey-60: #7F8089;
  --dino-grey-50: #95959D;
  --dino-grey-40: #AAAAB1;
  --dino-grey-30: #C0C0C5;
  --dino-grey-20: #D4D5D8;
  --dino-grey-10: #EAEAEC;
  --dino-grey-5: #F4F4F5;
  --dino-bg-default: #F1F1F1;

  // borders
  --border-color: #E8E8E8;
  --border-color-hover: #2A2B3B;
  --border-color-btn-hover: #98B133D5;
}

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dino-white);
  z-index: 1000;
}

.app {
  background-color: var(--dino-bg-default);
  scroll-behavior: smooth;
  transition: background-color 0.3s;
  &.active-screen-0 { background-color: var(--dino-bg-default); }
  &.active-screen-1 { background-color: #F4F5EE; }
  &.active-screen-2 { background-color: #F2F5F6; }
  &.active-screen-3 { background-color: #F8F3EE; }
  &.active-screen-4 { background-color: #F3F3F6; }
  &.active-screen-5 { background-color: #F9F7F0; }
  &.active-screen-6 { background-color: #F4F5EE; }
  overflow-x: clip;
}

.heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: -2px;
  color: var(--dino-dark);
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: .3;
  .label-icon {
    width: 24px;
    height: 24px;
  }
  .label-text {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: var(--dino-dark);
    text-transform: uppercase;
  }
}

.button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  padding: 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  cursor: pointer;
  &:hover {}
  &:active {
    opacity: 0.8;
  }
  &.primary {
    padding: 20px 30px;
    height: 50px;
    background: var(--dino-green);
    color: var(--dino-white);
    border-radius: 100px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &.has-icon {
      padding: 13px 30px 13px 13px;
    }
  }
  &.secondary {
    text-decoration: underline;
    background: transparent;
    color: var(--dino-green);
  }
}

.switch {
  box-sizing: border-box;
  background: var(--dino-grey-10);
  width: 65px;
  height: 32px;
  border-radius: 16px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.active {
    justify-content: flex-end;
    background: var(--dino-green);
  }
  .switch-button {
    background: var(--dino-white);
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }
}

.green-text {
  color: var(--dino-green);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  box-sizing: border-box;
  background-color: var(--dino-white);
  position: relative;
  max-width: 560px;
  margin: 0 auto;
  padding: 90px 50px 50px;
  border-radius: 40px;
  border: 3px solid var(--dino-dark);
}

.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2px;
  text-align: center;
  color: var(--dino-dark);
  margin: 0 0 20px;
}

.modal-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: var(--dino-dark);
  margin: 0 0 50px;
}

.modal-dropdown {
  margin-bottom: 10px;
}

.modal-form {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: var(--dino-white);
  border-radius: 99px;
  border: 1px solid var(--border-color);
  .modal-input {
    display: flex;
    flex: 1;
    padding: 0 10px 0 20px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: var(--dino-dark);
    &::placeholder {
      color: var(--dino-grey-30);
    }
  }
}

.dropdown {}
.dropdown-toggle {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 5px 20px;
  border-radius: 999px;
  border: 1px solid var(--border-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  max-width: 454px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background: var(--dino-white);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.dropdown-item {
  padding: 10px 0;
  cursor: pointer;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11;
  width: 550px;
  height: 124px;
  .notification-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: var(--dino-white);
    border-radius: 40px;
    border: 3px solid var(--dino-dark);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
}

@media (max-width: 576px) {
  .app-loader {
    img {
      width: 200px;
      height: 200px;
    }
  }
  .heading {
    font-size: 36px;
    line-height: 39px;
    letter-spacing: -3px;
  }
  .label {
    justify-content: center;
    .label-icon {
      display: none;
    }
    .label-text {
      letter-spacing: 0.1em;
    }
  }
  .button {
    &.primary {
      letter-spacing: 0.05em;
    }
  }
  .switch {
    width: 37px;
    height: 18px;
    border-radius: 9px;
    padding: 2px;
    .switch-button {
      width: 14px;
      height: 14px;
      border-radius: 7px;
    }
  }
  .modal-content {
    max-width: 343px;
    padding: 90px 30px 50px;
    box-shadow: -4px 7px 50px 0px rgba(0, 0, 0, 0.25);
    .button {
      margin-top: 20px;
      width: 100%;
    }
  }
  .modal-title {
    font-size: 36px;
    line-height: 39px;
    letter-spacing: -2px;
    margin: 0 0 5px;
  }
  .modal-text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 40px;
  }
  .modal-dropdown {
    margin-bottom: 20px;
  }
  .modal-form {
    .modal-input {
      height: 40px;
    }
  }
  .dropdown-menu {
    max-width: 277px;
  }
  .notification {
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: auto;
    .notification-wrapper {
      border-radius: 40px 40px 0 0;
      border: 3px solid var(--dino-dark);
      border-bottom: 0;
      align-items: flex-start;
      gap: 10px;
      padding: 32px;
      font-size: 16px;
      line-height: 24px;
    }
    .notification-icon {
      width: 24px;
      height: 24px;
    }
  }
}

@media (min-width: 2560px) and (-webkit-min-device-pixel-ratio: 2) {}
