.header {
  position: fixed;
  top: 5px;
  width: 100%;
  z-index: 9;
  .header-row {
    height: 90px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 90px;
    border: 1px solid var(--border-color);
    background: var(--dino-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-column {
    display: flex;
    align-items: center;
  }
  .header-logo-container {
    display: flex;
    align-items: center;
    gap: 20px;
    .header-logo-description {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      gap: 5px;
      padding: 15px;
      border-radius: 10px;
      border: 1px solid var(--dino-grey-10);
    }
  }
  .header-cta-container {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
