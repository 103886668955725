.calculator {
  padding: 200px 0;
  background-color: var(--dino-white);
  .calculator-row {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .calculator-column {
    display: flex;
    align-items: center;
  }
  .calculator-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 425px;
  }
  .calculator-content-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .calculator-content-header-title {}
  .calculator-content-header-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: var(--dino-dark);
  }
  .calculator-content-footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .calculator-content-footer-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    color: var(--dino-dark);
  }
  .calculator-content-footer-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--dino-grey-30);
  }
  .calculator-slider-container {
    box-sizing: border-box;
    width: 657px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    flex: 1;
    padding: 80px;
    border-radius: 80px;
    border: 3px solid var(--dino-dark);
  }
  .calculator-slider-heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 1px;
    color: var(--dino-dark);
  }
  .calculator-slider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .calculator-slider-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .calculator-slider-info {
    display: flex;
    flex-direction: column;
  }
  .calculator-slider-value {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--dino-grey-70);
  }
  .calculator-slider-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 1px;
    color: var(--dino-grey-30);
    text-transform: uppercase;
  }
  .calculator-slider {
    position: relative;
    .active-track {
      position: absolute;
      width: 80%;
      height: 6px;
      left: 5px;
      top: 8px;
      border-radius: 3px;
      background: var(--dino-green);
      z-index: 0;
    }
    .calculator-slider-ruler {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      color: var(--dino-grey-50);
    }
  }
  .calculator-slider-input {
    .StepRangeSlider__tooltip {
      display: none;
    }
    .StepRangeSlider__thumb {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 3px solid var(--dino-dark);
      background: var(--dino-green);
    }
    .StepRangeSlider__handle {
      z-index: 1;
    }
    .StepRangeSlider__track {
      height: 12px;
      border: 3px solid var(--dino-dark);
      border-radius: 10px;
    }
  }
}

@media (max-width: 1199px) {
  .calculator {
    .calculator-row {
      max-width: 960px;
    }
    .calculator-slider-container {
      width: 570px;
    }
  }
}

@media (max-width: 576px) {
  .calculator {
    padding: 100px 0;
    .calculator-row {
      width: 100%;
      flex-direction: column;
      gap: 40px;
    }
    .calculator-content {
      padding: 0 32px;
      max-width: 311px;
      gap: 30px;
      align-items: center;
      text-align: center;
    }
    .calculator-content-header-title {
      letter-spacing: -2px;
    }
    .calculator-content-header-text {
      line-height: 24px;
      .br {
        display: none;
      }
    }
    .calculator-content-footer {
      gap: 15px;
    }
    .calculator-content-footer-title {
      font-size: 18px;
      line-height: 20px;
    }
    .calculator-content-footer-text {
      letter-spacing: 0.1em;
    }
    .calculator-slider-container {
      width: 343px;
      padding: 30px 20px;
      border-radius: 30px;
    }
    .calculator-slider-heading {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: -1px;
      text-align: center;
    }
    .calculator-slider-label {
      letter-spacing: 0.05em;
    }
    .calculator-slider-price {
      .heading {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1px;
      }
    }
  }
}
