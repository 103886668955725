.mobile-menu {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 10;
  background-color: transparent;
}
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #00000080;
}
.mobile-menu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background: var(--dino-white);
  border: 3px solid var(--dino-dark);
  position: absolute;
  top: -80px;
  right: 10px;
}
.mobile-menu-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
  background: var(--dino-white);
  border-radius: 30px 30px 0 0;
  border: 3px solid var(--dino-dark);
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .button {
    width: 100%;
    margin-top: 20px;
  }
}
.mobile-menu-made-with-love-wrapper {
  display: flex;
  justify-content: center;
}
.mobile-menu-made-with-love {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--dino-grey-10);
  font-size: 14px;
}
